<template>
  <b-row>
    <b-col md="12">
      <b-card
        title="Expertises soumises"
        no-body
      >
        <b-card-body>
          <b-row>
            <b-col md="12">
              <b-card
                title="Expertises soumises"
                no-body
              >
                Expertises soumises
              </b-card>
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                size="sm"
                class="float-right mb-1 ml-1"
                onclick="history.back()"
              >
                <feather-icon icon="ArrowLeftCircleIcon" />
                Retour
              </b-button>
            </b-col>
          </b-row>
          <div class="d-flex justify-content-between flex-wrap">
            <!-- sorting  -->
            <b-form-group
              label="Trier"
              label-size="sm"
              label-align-sm="left"
              label-cols-sm="2"
              label-for="sortBySelect"
              class="mb-md-0"
            >
              <b-input-group size="sm">
                <b-form-select
                  id="sortBySelect"
                  v-model="sortBy"
                  :options="sortOptions"
                >
                  <template #first>
                    <option value="">
                      aucun
                    </option>
                  </template>
                </b-form-select>
                <b-form-select
                  v-model="sortDesc"
                  size="sm"
                  :disabled="!sortBy"
                >
                  <option :value="false">
                    Croissant
                  </option>
                  <option :value="true">
                    Décroissant
                  </option>
                </b-form-select>
              </b-input-group>
            </b-form-group>

            <!-- filter -->
            <b-form-group
              label="Filtrer"
              label-cols-sm="2"
              label-align-sm="left"
              label-size="sm"
              label-for="filterInput"
              class="mb-0"
            >
              <b-input-group size="sm">
                <b-form-input
                  id="filterInput"
                  v-model="filter"
                  type="search"
                  placeholder="Rechercher"
                />
                <b-input-group-append>
                  <b-button
                    :disabled="!filter"
                    @click="filter = ''"
                  >
                    Effacer
                  </b-button>
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
          </div>
        </b-card-body>

        <b-table
          striped
          hover
          responsive
          small
          :busy="loader"
          :empty-filtered-text="'Aucun résultat n\'a trouvé'"
          :empty-text="'Aucun résultat n\'a trouvé'"
          show-empty
          class="position-relative"
          :per-page="perPage"
          :current-page="currentPage"
          :items="ExpertisesByUser"
          :fields="fields"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :sort-direction="sortDirection"
          :filter="filter"
          :filter-included-fields="filterOn"
          @filtered="onFiltered"
        >
          <template #table-busy>
            <div class="d-flex justify-content-center mb-3">
              <b-spinner
                style="width: 2rem; height: 2rem"
                variant="primary"
                label="Large Spinner"
                type="grow"
                class="mt-2"
              />
              <span class="sr-only">Chargement en cours...</span>
            </div>
          </template>
          <template v-slot:cell(index)="data">
            {{ data.index + 1 }}
          </template>
          <template v-slot:cell(plaignant)="data">
            {{ data.item.user.lastname }} {{ data.item.user.firstname }}
          </template>
          <!-- Column: Actions -->
          <template #cell(actions)="data">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              size="sm"
              class="float-right mb-1"
              :to="{ name: 'espace-transporteur.detail-expertise', params: { id: data.item.id } }"
            >
              Voir le détail
              <feather-icon icon="ArrowRightCircleIcon" />

            </b-button>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              v-b-modal.validation
              variant="danger"
              size="sm"
              class="float-right mb-1 mr-2"
              @click="pass(data)"
            >
              Fermer
              <feather-icon icon="CloseIcon" />

            </b-button>
          </template>
        </b-table>
        <b-modal
          id="validation"
          ref="my-modal"
          title="Clôture de la demande"
          modal-class="modal-success"
          button-size="sm"
          cancel-variant="outline-secondary"
          size="xl"
          no-close-on-backdrop
          hide-footer
        >
          <div
            v-for="(data, index) in items.experts"
            :key="index"
          >
            <b-row
              v-if="current === index"
              class="justify-content-md-center"
            >
              <b-col
                md="12"
                class="mb-2"
              >
                <validation-observer
                  ref="accountRules"
                >
                  <b-form>
                    <b-row>
                      <b-col cols="12">
                        <b-form-group
                          label="Note de l'expert sur 5"
                          label-for="note"
                        >
                          <b-form-rating
                            v-model="form.note"
                            variant="warning"
                            class="mb-2"
                          />
                        </b-form-group>
                      </b-col>
                      <b-col cols="12">
                        <b-form-group
                          label="Commentaire"
                          label-for="description"
                        >
                          <b-form-textarea
                            id="description"
                            v-model="form.commentaire"
                            placeholder="Commentaire"
                            rows="3"
                          />
                        </b-form-group>
                      </b-col>
                    </b-row>
                  </b-form>
                </validation-observer>
              </b-col>
              <b-col md="6" />
              <b-col md="12">
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="danger"
                  class="float-left"
                  onclick="history.back()"
                >
                  <feather-icon icon="ArrowLeftCircleIcon" />
                  Annuler
                </b-button>
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="success"
                  class="float-right"
                  @click="next(data.id, items.id)"
                >
                  <feather-icon icon="ArrowRightCircleIcon" />
                  Soumettre
                </b-button>
              </b-col>
            </b-row>

          </div>
          <div v-if="items && items.experts && items.experts.length === 0">
            <h5>Voullez-vous vraiment fermer cette demandes? Aucun expert n'y a encore repondu!!</h5>
            <b-col md="12">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="danger"
                class="float-left"
                onclick="history.back()"
              >
                <feather-icon icon="ArrowLeftCircleIcon" />
                Annuler
              </b-button>
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="success"
                class="float-right"
                @click="changerStatusExpertise('Fermée')"
              >
                <feather-icon icon="ArrowRightCircleIcon" />
                Soumettre
              </b-button>
            </b-col>
          </div>
        </b-modal>
        <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
          <!-- page length -->
          <b-form-group
            label="Par Page"
            label-cols="6"
            label-align="left"
            label-size="sm"
            label-for="sortBySelect"
            class="text-nowrap mb-md-0 mr-1"
          >
            <b-form-select
              id="perPageSelect"
              v-model="perPage"
              size="sm"
              inline
              :options="pageOptions"
            />
          </b-form-group>

          <!-- pagination -->
          <div>
            <b-pagination
              v-model="currentPage"
              :total-rows="ExpertisesByUser.length"
              :per-page="perPage"
              first-number
              last-number
              prev-class="prev-item"
              next-class="next-item"
              class="mb-0 pagination-center"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </div>
        </b-card-body>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import Ripple from 'vue-ripple-directive'
import { ref, onMounted, reactive } from '@vue/composition-api'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import { ValidationObserver } from 'vee-validate'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {
  BRow,
  BCol,
  BCard,
  BTable,
  BFormGroup,
  BFormSelect,
  BForm,
  BPagination,
  BFormTextarea,
  BInputGroup,
  BInputGroupAppend,
  BButton,
  BCardBody,
  BFormInput,
  BSpinner,
  BFormRating,
} from 'bootstrap-vue'

// eslint-disable-next-line import/no-cycle
import useExpertises from '@/services/expertise/expertiseservice'
// eslint-disable-next-line import/no-cycle
import useAuthUser from '@/services/authentification/userService'
// eslint-disable-next-line import/no-cycle
import store from '@/store'
// eslint-disable-next-line import/no-cycle
import router from '@/router'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BTable,
    BFormTextarea,
    BFormGroup,
    BFormSelect,
    ValidationObserver,
    BForm,
    BFormRating,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BCardBody,
    BSpinner,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
  },
  directives: {
    Ripple,
  },
  setup() {
    const {
      loader, Success, commentaires, saveAppreciation, changeStatusExpertise, getAllExpertisesByUser, ExpertisesByUser, deleteExpertiseById,
    } = useExpertises()
    const {
      getAuthUser, user,
    } = useAuthUser()
    const perPage = 10
    const pageOptions = [3, 5, 10]
    const totalRows = ref(1)
    const currentPage = ref(1)
    const sortBy = ''
    const sortDesc = false
    const sortDirection = 'asc'
    const filter = null
    const filterOn = []
    const infoModal = {
      id: 'info-modal',
      title: '',
      content: '',
    }
    const deleteExpertise = async id => {
      await deleteExpertiseById(id)
    }
    const fields = [
      { key: 'index', label: 'N°' },
      { key: 'reference', label: 'REFERENCE', sortable: true },
      { key: 'type_expertise.libelle', label: 'TYPE D\'EXPERTISE', sortable: true },
      { key: 'question', label: 'OBJET', sortable: true },
      { key: 'created_at', label: 'DTE DE PLAINTE', sortable: true },
      { key: 'actions' },
    ]
    const form = reactive({
      expertise_id: null,
      note: 0,
      expert_id: null,
      commentaire: '',
    })

    onMounted(async () => {
      await getAuthUser()
      await getAllExpertisesByUser()
    })
    const saveCommentaire = async (expertId, expertiseId) => {
      form.expert_id = expertId
      form.expertise_id = expertiseId
      await saveAppreciation({ ...form })
      form.commentaire = ''
      form.expert_id = null
    }
    const items = ref([])
    const changerStatusExpertise = async status => {
      const item = JSON.parse(localStorage.getItem('expertise'))
      await changeStatusExpertise(item.id, { statut: status, expert_id: JSON.parse(store.state.authStore.user).id, expertise_id: item.id })
      if (Success.value) {
        router.push({ name: 'espace-transporteur.expertises-traitees', params: { id: item.id } })
      }
      localStorage.removeItem('expertise')
    }
    const onFiltered = filteredItems => {
      // Trigger pagination to update the number of buttons/pages due to filtering
      totalRows.value = filteredItems.length
      currentPage.value = 1
    }
    const pass = data => {
      items.value = data.item
      localStorage.setItem('expertise', JSON.stringify(data.item))
    }
    const formatter = value => value.toUpperCase()
    return {
      perPage,
      pageOptions,
      totalRows,
      currentPage,
      sortBy,
      sortDesc,
      sortDirection,
      filter,
      filterOn,
      infoModal,
      pass,
      fields,
      deleteExpertiseById,
      onFiltered,
      changerStatusExpertise,
      formatter,
      loader,
      form,
      Success,
      user,
      deleteExpertise,
      commentaires,
      saveCommentaire,
      items,
      ExpertisesByUser,
    }
  },
  data() {
    return {
      datas: {},
      current: 0,
    }
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => ({ text: f.label, value: f.key }))
    },
  },
  methods: {
    validationForm() {
      return new Promise((resolve, reject) => {
        this.$refs.accountRules.validate().then(success => {
          if (success) {
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
    handleOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault()
      // Trigger submit handler
      this.handleSubmitModal()
    },
    handleSubmitModal() {
      // Exit when the form isn't valid
      this.suspendreDevis(this.devisCode, this.stateDevis)
      // Hide the modal manually
      this.$nextTick(() => {
        this.$refs['my-modal'].toggle('#toggle-btn')
      })
    },
    next(expertId, expertiseId) {
      this.saveCommentaire(expertId, expertiseId)
      console.log(this.current)
      if (this.items.experts.length === this.current + 1) {
        console.log('azerty')
        this.changerStatusExpertise('Fermée')
      }
      this.current += 1
    },
  },
}
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
<style>
@import '../../../../../assets/css/chat.css';
</style>
